import classNames from 'classnames';
import './index.css';

type Props = {
    type: 'Error';
    children: JSX.Element | string;
};

const Alert = ({ type, children }: Props) => {
    return (
        <div
            className={classNames('UI-Alert', {
                'UI-Alert--error': type === 'Error',
            })}
        >
            {children}
        </div>
    );
};

export { Alert };
