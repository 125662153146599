import React, { ForwardedRef } from 'react';
import './index.css';

type Textarea = {
    name: string;
    label: string | JSX.Element;
    rows?: number;
};

const Textarea = React.forwardRef(
    ({ name, label, rows = 3 }: Textarea, ref: ForwardedRef<HTMLTextAreaElement | null>) => (
        <div className="UI-Textarea">
            <label className="UI-Textarea__label">
                <span className="UI-Textarea__field-name">{label}</span>
                <textarea ref={ref} name={name} className="UI-Textarea__textarea" rows={rows} />
            </label>
        </div>
    )
);

export { Textarea };
