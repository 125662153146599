import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { SyntheticEvent, useRef } from 'react';
import './index.css';
import { Textarea } from 'shared/ui/Textarea';
import { Button } from 'shared/ui/Button';
import { Spin } from 'shared/ui/Spin';
import { Alert } from '../../shared/ui/Alert';
import { ErrorResponse } from './data';

type UploadProps = {
    upload: MutationTrigger<any>;
    isLoading: boolean;
    error: ErrorResponse;
};

const Upload = ({ upload, isLoading, error }: UploadProps) => {
    const proteinTextareaEl = useRef<HTMLTextAreaElement>(null);
    const mutationTextareaEl = useRef<HTMLTextAreaElement>(null);

    const proteinExampleText = 'MKGMSKMPQFNLRWPREVLDLVRKVAEENGRSVNSEIYQRVMESFKKEGRIGA';
    const mutationExampleText = 'D20A';

    const handlePasteExample = (): void => {
        if (proteinTextareaEl && proteinTextareaEl.current) {
            proteinTextareaEl.current.value = proteinExampleText;
        }
        if (mutationTextareaEl && mutationTextareaEl.current) {
            mutationTextareaEl.current.value = mutationExampleText;
        }
    };

    const handleSubmitForm = (event: SyntheticEvent) => {
        event.preventDefault();
        upload({
            protein: (proteinTextareaEl && proteinTextareaEl.current && proteinTextareaEl.current.value) ?? '',
            mutation: (mutationTextareaEl && mutationTextareaEl.current && mutationTextareaEl.current.value) ?? '',
        });
    };

    const headerTextArea = (name: string) => (
        <div className="F-Upload__header-text-area">
            <span>{name}</span>
            <span className="F-Upload__past-example" onClick={handlePasteExample}>
                paste example
            </span>
        </div>
    );

    return (
        <form className="F-Upload" onSubmit={handleSubmitForm}>
            {error && (
                <div className="F-Upload__container-alert">
                    <Alert type="Error">{error.data.error}</Alert>
                </div>
            )}
            <Textarea
                ref={proteinTextareaEl}
                name="protein"
                label={headerTextArea('Wild-type protein sequence')}
                rows={6}
            />
            <Textarea ref={mutationTextareaEl} name="mutation" label="Mutation code" rows={6} />
            <div className="F-Upload__footer">
                {isLoading ? (
                    <div className="F-Upload__wrap-spin">
                        <Spin className="F-Upload__spin" />
                    </div>
                ) : (
                    <Button>Predict</Button>
                )}
            </div>
        </form>
    );
};

export { Upload };
