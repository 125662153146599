import './index.css';

const About = () => (
    <div className="P-About">
        <div className="container">
            <div className="App__title">
                <h1>About</h1>
            </div>
            <h2>Overview</h2>
            <p>
                PROSTATA is a tool for accurate prediction of changes in protein stability due to point mutations.
                PROSTATA utilizes five models consisting of a{' '}
                <a href="https://github.com/facebookresearch/esm" target="_blank">
                    ESM-2
                </a>{' '}
                transformer backbone that produces the embeddings for wild-type and mutated proteins and the regression
                head that combines the embeddings in various ways to predict ∆∆G, the change in the Gibbs free energy of
                unfolding. The final predictions are made by an ensemble of five models.
            </p>
            <h2>Usage</h2>
            <p>
                PROSTATA web-service is free to access and can be used for the simple use-case of predicting ∆∆G for a
                single point mutation. For the analysis of a larger number of mutations or more complex use-cases we
                recommended to use python implementation available on{' '}
                <a href="https://github.com/mitiau/PROSTATA">GitHub</a>.
            </p>
            <h2>Input</h2>
            <p>
                Provide as input the wild-type protein sequence in the first field and mutation code string in the
                second field in the format {'<wild-type amino acid><position><mutant-type amino acid>'}, for example
                “Q8H” indicates that the mutation exchanged amino acid Q (glutamine) in position 8 with amino acid H
                (histidine).
            </p>
            <h2>Output</h2>
            <p>As an output, PROSTATA will provide the predicted ∆∆G value.</p>
            <h2>Contact</h2>
            <p>
                PROSTATA was developed{' '}
                <a href="http://airi.net/" target="_blank">
                    AIRI
                </a>
            </p>
            <p>
                {' '}
                Contact e-mail: <a href="mailto:bioinformatics@airi.net">bioinformatics@airi.net</a>
            </p>
            <h2>Citation</h2>
            <p>PROSTATA: Protein Stability Assessment using Transformers</p>
            <p>
                Dmitriy Umerenkov, Tatiana I. Shashkova, Pavel V. Strashnov, Fedor Nikolaev, Maria Sindeeva, Nikita V.
                Ivanisenko, Olga L. Kardymon
            </p>
            <p>
                bioRxiv 2022.12.25.521875; doi:{' '}
                <a href="https://doi.org/10.1101/2022.12.25.521875" target="_blank">
                    https://doi.org/10.1101/2022.12.25.521875
                </a>
            </p>
        </div>
    </div>
);

export { About };
