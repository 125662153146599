import './index.css';
import { UploadResponse } from '../Upload/data';

const Result = ({ data }: { data: UploadResponse | undefined }) => {
    return (
        <div className="F-Result">
            <div className="container">
                <div className="F-Result__number">Predicted ∆∆G: {data && data.number} kcal/mol</div>
            </div>
        </div>
    );
};

export { Result };
