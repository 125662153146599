import { Link, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import 'normalize.css';
import './index.css';
import logo from '../shared/assets/images/airi-full-logo.svg';
import { Home } from 'pages/Home';
import { About } from 'pages/About';
import { appApi } from 'shared/api';
import { useDispatch } from 'react-redux';

const Layout = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const handleHomeClick = (): void => {
        dispatch(appApi.util.resetApiState());
    };

    return (
        <div className="App">
            <header className="App__header">
                <div className="container">
                    <div className="App__header-wrap-items">
                        <img src={logo} className="App__logo" alt="logo" />
                        <nav className="App__menu-nav">
                            <ul className="App__menu-ul">
                                <li className="App__menu-li">
                                    {pathname !== '/' ? (
                                        <Link to="/" className="App__menu-link">
                                            Home
                                        </Link>
                                    ) : (
                                        <span className="App__menu-link" onClick={handleHomeClick}>
                                            Home
                                        </span>
                                    )}
                                </li>
                                <li className="App__menu-li">
                                    <Link to="/about" className="App__menu-link">
                                        About
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                        <div className="App__out-wrap-links">
                            <a href="https://bioinformatics.airi.net" target="_blank" className="App__out-link" rel="noreferrer">
                                Bioinformatics group
                            </a>
                            <a href="https://airi.net/" target="_blank" className="App__out-link" rel="noreferrer">
                                AIRI
                            </a>
                        </div>
                    </div>
                </div>
            </header>
            <div className="App__main">
                <Outlet />
            </div>
            <footer className="App__footer">
                <p style={{ maxWidth: 500, margin: '0 auto' }}>
                    <b>Read and cite:</b>{' '}
                    <a color="#14D6BCFF" href="https://academic.oup.com/bioinformatics/article/39/11/btad671/7342240" target='_blank'>
                        PROSTATA: a framework for protein stability assessment using transformers
                    </a>
                </p>
                <br />
                <br />© 2024, AIRI
            </footer>
        </div>
    );
};

const App = () => {
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="about" element={<About />} />
            </Route>
        </Routes>
    );
};

export { App };
