import classNames from 'classnames';
import './index.css';

type SpinProps = {
    className?: string;
};

const Spin = ({ className }: SpinProps) => {
    return <div className={classNames('UI-Spin', className)} />;
};

export { Spin };
