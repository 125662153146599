import { useUploadMutation } from 'shared/api';
import { Upload } from 'features/Upload';
import { Result } from 'features/Result';
import { ErrorResponse } from 'features/Upload/data';

const Home = () => {
    const [upload, { isLoading, isSuccess, data, error }] = useUploadMutation();
    return (
        <>
            <div className="App__title">
                <h1>PROSTATA</h1>
                <p>Protein Stability Assessment using Transformers</p>
            </div>

            {isSuccess ? (
                <Result data={data} />
            ) : (
                <Upload error={error as ErrorResponse} upload={upload} isLoading={isLoading} />
            )}
        </>
    );
};

export { Home };
