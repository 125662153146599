import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { UploadRequest, UploadResponse } from 'features/Upload/data';

export const appApi = createApi({
    reducerPath: 'appApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/',
    }),
    endpoints: (builder) => ({
        upload: builder.mutation<UploadResponse, UploadRequest>({
            query: ({ protein, mutation }: UploadRequest) => {
                const data = new FormData();
                data.append('protein', protein);
                data.append('mutation', mutation);

                return {
                    url: `/api/upload`,
                    method: 'POST',
                    body: data,
                };
            },
        }),
    }),
});

export const { useUploadMutation } = appApi;
